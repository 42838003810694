import { Blocks } from 'blocks/Blocks'
import {CMSDomainsChannel} from 'cms-types'
import {EntryOverview} from 'layout/EntryOverview'
import {Hero, HeroBack} from 'layout/hero/Hero'
import React from 'react'
import {CMSDomainsChannelExtra} from './domains.data'

export const Domains: React.FC<CMSDomainsChannel & CMSDomainsChannelExtra> = (
	node
) => {
	const {title, description, parents, blocks, domains} = node

	return (
		<div>
			<HeroBack parent={parents[0]} />
			<Hero title={title} text={description} />
			<EntryOverview items={domains} />
			<Blocks blocks={blocks} />
		</div>
	)
}
