import React from 'react'
import Dropdown from 'react-dropdown'
import {fromModule} from 'util/styler/Styler'
import css from './Dropdown.module.scss'
import {Icon} from './Icon'

const styles = fromModule(css)

export const DropdownStyled: React.FC<{
	options: {value: string; label: string}[]
	onChange: (any) => void
	value: string
	placeholder?: string
	mod?: 'select'
}> = ({options, onChange, value, placeholder, mod}) => {
	const optionsArray = options.map((o) => ({
		...o,
		className: styles.dropdown.menu.option()
	}))

	return (
		<Dropdown
			options={optionsArray}
			onChange={onChange}
			value={value}
			placeholder={placeholder}
			className={styles.dropdown.mod(mod)()}
			controlClassName={styles.dropdown.control()}
			menuClassName={styles.dropdown.menu()}
			arrowClosed={
				<span className={styles.dropdown.arrow.mod('closed')()}>
					<Icon icon="chevron_down" />
				</span>
			}
			arrowOpen={
				<span className={styles.dropdown.arrow.mod('open')()}>
					<Icon icon="chevron_down" />
				</span>
			}
		/>
	)
}
