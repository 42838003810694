import React, {HTMLProps} from 'react'
import {Link} from 'util/link'
import {Variant} from 'util/styler'
import {fromModule} from 'util/styler/Styler'
import css from './Button.module.scss'
import {Icon} from './Icon'

const styles = fromModule(css)

type ButtonModType = {
	mod?: Variant<'outline'>
}

type ButtonIconType = 'arrow_diagonal' | 'arrow_diagonal_small' | 'arrow_left'

export const Button: React.FC<
	HTMLProps<HTMLAnchorElement> &
		ButtonModType & {
			to?: string
			as?: 'button'
			iconbefore?: ButtonIconType
			iconafter?: ButtonIconType
		}
> = ({as, mod, iconbefore, iconafter, children, ...props}) => {
	const ButtonTag: any = as || Link

	return (
		<ButtonTag
			{...props}
			className={styles.button.mergeProps(props).mod(mod)()}
		>
			{iconbefore && <ButtonIcon icon={iconbefore} />}
			<ButtonText>{children}</ButtonText>
			{iconafter && <ButtonIcon icon={iconafter} />}
		</ButtonTag>
	)
}

const ButtonText: React.FC = (props) => (
	<span {...props} className={styles.button.text()} />
)

const ButtonIcon: React.FC<{icon: ButtonIconType}> = ({icon}) => (
	<span className={styles.button.icon.mod(icon)()}>
		<Icon icon={icon} />
	</span>
)
