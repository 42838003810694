import {Blocks} from 'blocks/Blocks'
import {CMSResearch_detailChannel} from 'cms-types'
import {Container} from 'layout/Container'
import {Hero, HeroBack} from 'layout/hero/Hero'
import {Image} from 'layout/Image'
import {Wysiwyg} from 'layout/Wysiwyg'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './ResearchDetail.module.scss'

const styles = fromModule(css)

export const ResearchDetail: React.FC<CMSResearch_detailChannel> = (node) => {
	const {title, image, description, parents, hero_title, hero_text, blocks} =
		node

	return (
		<div className={styles.researchdetail()}>
			<HeroBack parent={parents[0]} />
			<Hero title={hero_title || title} text={hero_text} />
			{(image?.src || description) && (
				<div className={styles.researchdetail.intro()}>
					<Container>
						<div className={styles.researchdetail.intro.row()}>
							<div className={styles.researchdetail.intro.text()}>
								{description && <Wysiwyg>{description}</Wysiwyg>}
							</div>
							{image?.src && (
								<Image
									{...image}
									className={styles.researchdetail.intro.image()}
								/>
							)}
						</div>
					</Container>
				</div>
			)}
			<Blocks blocks={blocks} />
		</div>
	)
}
