import {Block} from 'blocks/Block'
import {CMSImagetextBlock} from 'cms-types'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Wysiwyg} from 'layout/Wysiwyg'
import {fromModule} from 'util/styler/Styler'
import css from './ImageText.module.scss'

const styles = fromModule(css)

export const ImageText: React.FC<CMSImagetextBlock> = ({
	image_position,
	image,
	button,
	text
}) => {
	if ((!image?.src || !button?.url) && !text) return null

	return (
		<Block type="imagetext" className={styles.imagetext()}>
			<Container>
				<div className={styles.imagetext.row.mod(image_position)()}>
					<div className={styles.imagetext.side()}>
						{image?.src && (
							<Image {...image} className={styles.imagetext.side.image()} />
						)}
						{button?.url && (
							<Button
								to={button.url}
								iconafter="arrow_diagonal"
								className={styles.imagetext.side.button()}
							>
								{button?.description}
							</Button>
						)}
					</div>
					{text && (
						<div className={styles.imagetext.content()}>
							<Wysiwyg className={styles.imagetext.content.text()}>
								{text}
							</Wysiwyg>
						</div>
					)}
				</div>
			</Container>
		</Block>
	)
}
