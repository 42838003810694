import {Block} from 'blocks/Block'
import {CMSImage, CMSListdomainsBlock, CMSWysiwyg} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {CMSListdomainsBlockExtra} from './listdomains.data'
import css from './Listdomains.module.scss'

const styles = fromModule(css)

type ListItemType = {
	title: string
	text?: CMSWysiwyg
}
type DomainItemType = {
	title: string
	url: string
	image?: CMSImage
}

export const Listdomains: React.FC<
	CMSListdomainsBlock & CMSListdomainsBlockExtra
> = ({title, text, items, domains, link}) => {
	return (
		<Block type="listdomains" title={title} className={styles.listdomains()}>
			<Container>
				{title && (
					<Title.Wrapper className={styles.listdomains.title()}>
						<Title.H2 mod={['dots', 'uppercase']}>{title}</Title.H2>
					</Title.Wrapper>
				)}
				{text && (
					<Wysiwyg className={styles.listdomains.text()}>{text}</Wysiwyg>
				)}
				{items?.length > 0 && (
					<div className={styles.listdomains.items()}>
						{items.map((item, i) => (
							<ListItem key={i} {...item} />
						))}
					</div>
				)}
				<Domains domains={domains} />
				{link?.url && (
					<LinkStyled
						to={link.url}
						iconafter="arrow_diagonal"
						className={styles.listdomains.link()}
						mod={['large']}
					>
						{link.description}
					</LinkStyled>
				)}
			</Container>
		</Block>
	)
}

const ListItem: React.FC<ListItemType> = ({title, text}) => {
	return (
		<div className={styles.listitem()}>
			<Title.H5 className={styles.listitem.title()}>{title}</Title.H5>
			{text && <Wysiwyg>{text}</Wysiwyg>}
		</div>
	)
}

const Domains: React.FC<{domains: DomainItemType[]}> = ({domains}) => {
	if (!domains || domains.length === 0) return null

	return (
		<div className={styles.domains()}>
			<p>
				<strong>
					Binnen het programma focussen we op volgende toepassingsdomeinen:
				</strong>
			</p>

			<div className={styles.domains.items()}>
				{domains.map((domain, i) => (
					<DomainItem {...domain} key={i} />
				))}
			</div>
		</div>
	)
}

const DomainItem: React.FC<DomainItemType> = ({title, url, image}) => {
	return (
		<div className={styles.domainitem()}>
			{image?.src && (
				<Link to={url}>
					<Image
						{...image}
						as="landscape"
						className={styles.domainitem.image()}
					/>
				</Link>
			)}
			<Title.H5 className={styles.domainitem.title()} mod="uppercase">
				<Link to={url}>{title}</Link>
			</Title.H5>
		</div>
	)
}
