import {CMSLibraryChannel} from 'cms-types'
import {Container} from 'layout/Container'
import {Hero} from 'layout/hero/Hero'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import {useRouter} from 'next/router'
import React from 'react'
import {formatDayMonthYear} from 'util/dates'
import {Variant} from 'util/styler'
import {fromModule} from 'util/styler/Styler'
import {CMSLibraryChannelExtra, CMSLibraryItem} from './library.data'
import css from './Library.module.scss'

const styles = fromModule(css)
const itemsPerPage = 15

const useLibraryRouter = () => {
	const router = useRouter()
	const shallowPush = (args: Record<string, string | number>) => {
		return router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					...args
				}
			},
			undefined,
			{shallow: true}
		)
	}

	return {
		page: parseInt(router.query.page as string, 10) || 0,
		setPage: (page) => shallowPush({page}),
		clear: () => shallowPush({page: 0})
	}
}

export const Library: React.FC<CMSLibraryChannel & CMSLibraryChannelExtra> = (
	node
) => {
	const t = useTranslation()
	const {title, description, children} = node
	const {page} = useLibraryRouter()

	const visibleChildren = children.filter(
		(_, i) => i >= page * itemsPerPage && i < (page + 1) * itemsPerPage
	)
	const pages = Math.ceil(children.length / itemsPerPage)

	return (
		<div className={styles.library()}>
			<Hero title={title} text={description} />
			<Container>
				<div className={styles.library.head()}>
					<LibraryColumn mod="small">{t.library.date}</LibraryColumn>
					<LibraryColumn mod={['small', 'maxwidth']}>
						{t.library.description}
					</LibraryColumn>
					<LibraryColumn mod={['small', 'minwidth']}>
						{t.library.link}
					</LibraryColumn>
				</div>
				{visibleChildren.map((child, i) => (
					<LibraryItem {...child} key={`${child.id}|${i}`} />
				))}
				{visibleChildren?.length === 0 && (
					<p className={styles.library.empty()}>{t.library.empty}</p>
				)}
				{pages > 1 && <LibraryPagination pages={pages} />}
			</Container>
		</div>
	)
}

const LibraryPagination: React.FC<{pages: number}> = ({pages}) => {
	const {page, setPage} = useLibraryRouter()

	return (
		<div className={styles.pagination()}>
			{Array.from({length: pages}).map((_, i) => (
				<a
					className={styles.pagination.item.is({active: i === page})()}
					onClick={() => setPage(i)}
				>
					{i + 1}
				</a>
			))}
		</div>
	)
}

const LibraryColumn: React.FC<{
	mod?: Variant<'small' | 'minwidth' | 'maxwidth'>
}> = ({mod, children}) => {
	return <div className={styles.column.mod(mod)()}>{children}</div>
}

const LibraryItem: React.FC<CMSLibraryItem> = ({
	date,
	title,
	description,
	link
}) => {
	if (!title) return null
	const t = useTranslation()
	const linkLabel = link?.type === 'file' ? 'Download' : link?.description

	return (
		<div className={styles.item()}>
			{date && (
				<LibraryColumn>{formatDayMonthYear(date, t.dateLocale)}</LibraryColumn>
			)}
			<LibraryColumn mod={['maxwidth']}>
				<div className={styles.item.content()}>
					<Title.H5 className={styles.item.content.title()} mod="inherit">
						{title}
					</Title.H5>
					{description && (
						<Wysiwyg className={styles.item.content.text()}>
							{description}
						</Wysiwyg>
					)}
				</div>
			</LibraryColumn>
			<LibraryColumn mod="minwidth">
				<p></p>
				{link?.url && (
					<LinkStyled
						to={link.url}
						iconafter="arrow_diagonal"
						className={styles.item.link()}
					>
						{linkLabel}
					</LinkStyled>
				)}
			</LibraryColumn>
		</div>
	)
}
