import {Block} from 'blocks/Block'
import {CMSTextBlock} from 'cms-types'
import {Container} from 'layout/Container'
import {Wysiwyg} from 'layout/Wysiwyg'
import {fromModule} from 'util/styler/Styler'
import css from './Text.module.scss'

const styles = fromModule(css)

export const Text: React.FC<CMSTextBlock> = ({text}) => {
	if (!text) return null

	return (
		<Block type="text">
			<Container>
				<Wysiwyg className={styles.text.text()}>{text}</Wysiwyg>
			</Container>
		</Block>
	)
}
