import {CMSNews_detailChannel, CMSNews_itemChannel} from 'cms-types'
import {Icon} from 'layout/Icon'
import {Image} from 'layout/Image'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import React from 'react'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './NewsCard.module.scss'

const styles = fromModule(css)

export const NewsCard: React.FC<
	| ({channel: 'news_detail'; link?: undefined} & CMSNews_detailChannel)
	| ({channel: 'news_item'} & CMSNews_itemChannel)
> = ({channel, url, title, description, image, link}) => {
	return (
		<Link
			to={channel === 'news_item' ? link?.url : url}
			className={styles.newscard()}
		>
			<div className={styles.newscard.image()}>
				{image?.src && <Image {...image} as="background" />}
				{channel === 'news_detail' && (
					<span className={styles.newscard.image.icon()}>
						<Icon icon="newspaper" />
					</span>
				)}
			</div>
			<div className={styles.newscard.content()}>
				<div>
					<Title.H4 as="h3">{title}</Title.H4>
					{description && <Wysiwyg>{description}</Wysiwyg>}
				</div>
				<span className={styles.newscard.content.icon()}>
					<Icon icon="arrow_diagonal" />
				</span>
			</div>
		</Link>
	)
}
