import {Block} from 'blocks/Block'
import {CMSImage, CMSImagelinksBlock, CMSLink, CMSWysiwyg} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {fromModule} from 'util/styler/Styler'
import css from './ImageLinks.module.scss'

const styles = fromModule(css)

export const ImageLinks: React.FC<CMSImagelinksBlock> = ({
	title,
	image,
	multiple_items,
	text,
	image_content,
	links,
	items
}) => {
	if (!title && !image?.src && !text) return null

	return (
		<Block type="imagelinks" title={title}>
			<Container>
				{title && multiple_items && (
					<Title.Wrapper className={styles.imagelinks.title()} mod="large">
						<Title.H2 mod={['dots', 'uppercase']}>{title}</Title.H2>
					</Title.Wrapper>
				)}
				{title && !multiple_items && (
					<Title.H2 className={styles.imagelinks.title()} mod="uppercase">
						{title}
					</Title.H2>
				)}
				<div className={styles.imagelinks.row()}>
					<div className={styles.imagelinks.image()}>
						<Image {...image} className={styles.imagelinks.image.img()} />
					</div>
					<div className={styles.imagelinks.content()}>
						{multiple_items ? (
							<ImageLinksItems items={items} />
						) : (
							<ImageLinksContent
								text={text}
								image_content={image_content}
								links={links}
							/>
						)}
					</div>
				</div>
			</Container>
		</Block>
	)
}

const ImageLinksContent: React.FC<{
	text: CMSWysiwyg
	image_content?: CMSImage
	links: CMSLink[]
}> = ({text, image_content, links}) => {
	if (!text && links?.length === 0) return null

	return (
		<div className={styles.content()}>
			{text && <Wysiwyg className={styles.content.text()}>{text}</Wysiwyg>}
			{image_content?.src && (
				<Image {...image_content} className={styles.content.image()} />
			)}
			{links?.length > 0 && (
				<div className={styles.content.links()}>
					{links.map((link) => (
						<LinkStyled
							key={link.url}
							to={link.url}
							iconafter="arrow_diagonal"
							className={styles.content.links.link()}
							mod={['fullwidth', 'large']}
						>
							{link.description}
						</LinkStyled>
					))}
				</div>
			)}
		</div>
	)
}

const ImageLinksItems: React.FC<{
	items: {
		text: CMSWysiwyg
		links: CMSLink[]
	}[]
}> = ({items}) => {
	if (items?.length === 0) return null

	return (
		<div className={styles.items()}>
			{items.map((item, i) => {
				if (!item) return null

				return (
					<div key={i} className={styles.items.item()}>
						{item.text && (
							<Wysiwyg className={styles.items.item.text()}>
								{item.text}
							</Wysiwyg>
						)}
						{item.links?.length > 0 && (
							<div className={styles.items.item.links()}>
								{item.links?.map((link) => (
									<LinkStyled
										key={link.url}
										to={link.url}
										iconafter="arrow_diagonal"
										className={styles.items.item.links.link()}
										mod={['fullwidth', 'large']}
									>
										{link.description}
									</LinkStyled>
								))}
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}
