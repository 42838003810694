// tslint:disable

function openShare(url, width, height) {
	const winTop = window.outerHeight / 2 - height / 2
	const winLeft = window.outerWidth / 2 - width / 2

	window.open(
		url,
		'sharer',
		'top=' +
			winTop +
			',left=' +
			winLeft +
			',toolbar=0,status=0,width=' +
			width +
			',height=' +
			height
	)
}

/**
 * @param text
 * Pre-populated UTF-8 and URL-encoded Tweet text. The passed text will appear pre-selected for a Twitter user to
 * delete or edit before posting. The length of your passed Tweet text should not exceed 280 characters when combined
 * with any passed hashtags, via, or url parameters. Passed Tweet text which causes the Tweet to exceed 280 characters
 * in length will require additional editing by a Twitter user before he or she can successfully post.
 *
 * @param url
 * A fully-qualified URL with a HTTP or HTTPS scheme, URL-encoded. The provided URL will be shortened by Twitter’s t.co
 * to the number of characters specified by short_url_length.
 *
 * @param hashtags
 * Allow easy discovery of Tweets by topic by including a comma-separated list of hashtag values without the
 * preceding # character.
 *
 * @returns {string}
 */

function facebookUrl(url) {
	if (!url) return null

	return (
		'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url)
	)
}

function twitterUrl(copy, url, hashtags) {
	if (!url) return null

	const base = 'https://twitter.com/share?'
	const args = []
	if (copy) args.push('text=' + encodeURIComponent(copy))
	if (url) args.push('url=' + encodeURIComponent(url))
	if (hashtags) args.push('hashtags=' + hashtags.join(','))
	return base + args.join('&')
}

function linkedInUrl(url, title, description) {
	if (!url) return null

	const args = []
	args.push('url=' + encodeURIComponent(url))
	args.push('title=' + encodeURIComponent(title || ''))
	args.push('summary=' + encodeURIComponent(description || ''))
	return 'https://www.linkedin.com/shareArticle?mini=true&' + args.join('&')
}

export const facebook = (url) => {
	openShare(facebookUrl(url), 580, 580)
}

export const twitter = (copy, url?, hashtags?) => {
	openShare(twitterUrl(copy, url, hashtags), 520, 300)
}

export const linkedIn = (url, title?, description?) => {
	openShare(linkedInUrl(url, title, description), 500, 500)
}

export const facebookLink = (url) => {
	return {
		href: facebookUrl(url),
		onClick: (e) => {
			e.preventDefault()
			facebook(url)
		}
	}
}

export const twitterLink = (copy, url?, hashtags?) => {
	return {
		href: twitterUrl(copy, url, hashtags),
		onClick: (e) => {
			e.preventDefault()
			twitter(copy, url, hashtags)
		}
	}
}

export const linkedInLink = (url, title?, description?) => {
	return {
		href: linkedInUrl(url, title, description),
		onClick: (e) => {
			e.preventDefault()
			linkedIn(url, title, description)
		}
	}
}

export const mailLink = (url) => {
	return {href: `mailto:?body=${url}`}
}
