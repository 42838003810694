import { Blocks } from 'blocks/Blocks'
import {CMSResearchChannel} from 'cms-types'
import {EntryOverview} from 'layout/EntryOverview'
import {Hero, HeroBack} from 'layout/hero/Hero'
import React from 'react'
import {CMSResearchChannelExtra} from './research.data'

export const Research: React.FC<CMSResearchChannel & CMSResearchChannelExtra> =
	(node) => {
		const {title, description, parents, blocks, research} = node

		return (
			<div>
				<HeroBack parent={parents[0]} />
				<Hero title={title} text={description} />
				<EntryOverview items={research} />
				<Blocks blocks={blocks} />
			</div>
		)
	}
