import {CMSImage, CMSWysiwyg} from 'cms-types'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import React from 'react'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './EntryCard.module.scss'

const styles = fromModule(css)

export type EntryCardType = {
	url?: string
	title: string
	description?: CMSWysiwyg
	image?: CMSImage
	skipLinks?: boolean
}

export const EntryCard: React.FC<EntryCardType> = ({
	url,
	title,
	description,
	image,
	skipLinks
}) => {
	if (!url) return null
	const t = useTranslation()

	return (
		<div className={styles.entrycard()}>
			<div className={styles.entrycard.content()}>
				{image?.src && (
					<Link to={url}>
						<Image
							{...image}
							as="landscape"
							className={styles.entrycard.content.image()}
						/>
					</Link>
				)}
				<Title.H3>
					<Link to={url}>{title}</Link>
				</Title.H3>
				{description && (
					<Wysiwyg className={styles.entrycard.content.description()}>
						{description}
					</Wysiwyg>
				)}
			</div>
			{!skipLinks && <LinkStyled
				to={url}
				iconafter="arrow_diagonal"
				className={styles.entrycard.link()}
				mod={['large', 'fullwidth']}
			>
				{`${t.readmore}\n${title}`}
			</LinkStyled>}
		</div>
	)
}
