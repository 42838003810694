import React, {useEffect, useRef} from 'react'

import css from './Dots.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Dots: React.FC = () => {
	const canvasEl = useRef<HTMLCanvasElement>(null)

	useEffect(() => {
		const controller = new NodesController()
		controller.init(canvasEl.current)

		return () => controller.remove()
	}, [])

	return <canvas ref={canvasEl} className={styles.dots()} />
}

const color = '#36337d'

class NodesController {
	// Settings
	density = 30
	baseRadius = 1
	reactionSensitivity = 1
	border = 40

	points: any[] = []
	mouse = {x: -1000, y: -1000}

	animation: any = null
	canvas: any = null
	context: any = null

	init(canvas: HTMLCanvasElement) {
		// Set up the visual canvas
		this.canvas = canvas
		this.context = canvas.getContext('2d')
		this.canvas.style.display = 'block'
		this.canvas.width = canvas.offsetWidth
		this.canvas.height = canvas.offsetHeight

		this.canvas.addEventListener('mousemove', this.mouseMove, false)
		this.canvas.addEventListener('mouseout', this.mouseOut, false)
		window.addEventListener('resize', this.onWindowResize, false)

		this.preparePoints()
		this.draw()
	}

	remove() {
		this.canvas.removeEventListener('mousemove', this.mouseMove)
		this.canvas.removeEventListener('mouseout', this.mouseOut)
		window.removeEventListener('resize', this.onWindowResize)
	}

	preparePoints() {
		// Clear the current points
		this.points = []
		for (
			let i = this.border;
			i < this.canvas.height - this.border;
			i += this.density
		) {
			for (
				let j = this.border;
				j < this.canvas.width - this.border;
				j += this.density
			) {
				this.points.push({x: j, y: i, originalX: j, originalY: i})
			}
		}
	}

	updatePoints() {
		var i, currentPoint, theta, distance

		for (i = 0; i < this.points.length; i++) {
			currentPoint = this.points[i]

			theta = Math.atan2(
				currentPoint.y - this.mouse.y,
				currentPoint.x - this.mouse.x
			)
			distance =
				(this.reactionSensitivity * 100) /
				Math.sqrt(
					(this.mouse.x - currentPoint.x) * (this.mouse.x - currentPoint.x) +
						(this.mouse.y - currentPoint.y) * (this.mouse.y - currentPoint.y)
				)

			currentPoint.x +=
				Math.cos(theta) * distance +
				(currentPoint.originalX - currentPoint.x) * 0.05
			currentPoint.y +=
				Math.sin(theta) * distance +
				(currentPoint.originalY - currentPoint.y) * 0.05
		}
	}

	drawPoints() {
		var i, currentPoint

		for (i = 0; i < this.points.length; i++) {
			currentPoint = this.points[i]

			// Draw the dot.
			this.context.fillStyle = color
			this.context.strokeStyle = color

			this.context.beginPath()
			this.context.arc(
				currentPoint.x,
				currentPoint.y,
				this.baseRadius,
				0,
				Math.PI * 2,
				true
			)
			this.context.closePath()
			this.context.fill()
		}
	}

	draw() {
		this.animation = requestAnimationFrame(() => this.draw())
		this.clear()
		this.updatePoints()
		this.drawPoints()
	}

	clear() {
		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
	}

	mouseMove = (event) => {
		this.mouse.x = event.offsetX || event.layerX - this.canvas.offsetLeft
		this.mouse.y = event.offsetY || event.layerY - this.canvas.offsetTop
	}

	mouseOut = (event) => {
		this.mouse.x = -1000
		this.mouse.y = -1000
	}

	// Resize and redraw the canvas.
	onWindowResize = () => {
		cancelAnimationFrame(this.animation)
		this.canvas.width = this.canvas.offsetWidth
		this.canvas.height = this.canvas.offsetHeight
		this.preparePoints()
		this.draw()
	}
}
