import {Block} from 'blocks/Block'
import {CMSContactformBlock} from 'cms-types'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {DropdownStyled} from 'layout/Dropdown'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import React, {useState} from 'react'
import {fromModule} from 'util/styler/Styler'
import {CMSContactformBlockExtra} from './contactform.data'
import css from './Contactform.module.scss'

const styles = fromModule(css)

type FormData = {
	fname: string
	lname: string
	email: string
	research: string
	domain: string
	question: string
	newsletter: boolean
	agreement: boolean
}

const initialData: FormData = {
	fname: '',
	lname: '',
	email: '',
	research: null,
	domain: null,
	question: '',
	newsletter: false,
	agreement: false
}

export const Contactform: React.FC<
	CMSContactformBlock & CMSContactformBlockExtra
> = ({options_research, options_domains}) => {
	const [submitting, setSubmitting] = useState(false)
	const [state, setState] = useState(initialData)
	const t = useTranslation().contact

	const optionsResearch =
		options_research?.length > 0
			? options_research.map(function (o) {
					return {value: o.path, label: o.title}
			  })
			: []
	const optionsDomains =
		options_domains?.length > 0
			? options_domains.map(function (o) {
					return {value: o.path, label: o.title}
			  })
			: []

	const submitData = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (submitting) {
			return
		}
		setSubmitting(true)

		fetch('/api/contact', {
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(state)
		}).then((res) => {
			console.log('Response received')
			console.log(res.status)
			if (res.status === 200) {
				setState(initialData)
				setSubmitting(false)
			} else {
				setSubmitting(false)
			}
		})
	}

	if (submitting) {
		return (
			<Block type="contactform">
				<Container>
					<Wysiwyg>{t.thankyou}</Wysiwyg>
				</Container>
			</Block>
		)
	}

	return (
		<Block type="contactform">
			<Container>
				<form className={styles.contactform()} onSubmit={submitData}>
					<label className={styles.contactform.field()}>
						<p className={styles.contactform.field.label()}>{t.fname}</p>
						<input
							type="text"
							name="fname"
							required
							className={styles.contactform.field.input()}
							value={state.fname}
							onChange={(e) => setState({...state, fname: e.target.value})}
						/>
					</label>
					<label className={styles.contactform.field()}>
						<p className={styles.contactform.field.label()}>{t.lname}</p>
						<input
							type="text"
							name="lname"
							required
							className={styles.contactform.field.input()}
							value={state.lname}
							onChange={(e) => setState({...state, lname: e.target.value})}
						/>
					</label>
					<label className={styles.contactform.field()}>
						<p className={styles.contactform.field.label()}>{t.email}</p>
						<input
							type="email"
							name="email"
							required
							className={styles.contactform.field.input()}
							value={state.email}
							onChange={(e) => setState({...state, email: e.target.value})}
						/>
					</label>
					<label className={styles.contactform.field()}>
						<p className={styles.contactform.field.label()}>{t.research}</p>
						<DropdownStyled
							options={optionsResearch}
							mod="select"
							value={state.research}
							onChange={(value) => setState({...state, research: value})}
						/>
					</label>
					<label className={styles.contactform.field()}>
						<p className={styles.contactform.field.label()}>{t.domain}</p>
						<DropdownStyled
							options={optionsDomains}
							mod="select"
							value={state.domain}
							onChange={(value) => setState({...state, domain: value})}
						/>
					</label>
					<label className={styles.contactform.field()}>
						<p className={styles.contactform.field.label()}>{t.question}</p>
						<textarea
							name="question"
							className={styles.contactform.field.textarea()}
							value={state.question}
							onChange={(e) => setState({...state, question: e.target.value})}
						/>
					</label>
					<label className={styles.contactform.field.mod('checkbox')()}>
						<input
							type="checkbox"
							name="newsletter"
							checked={state.newsletter}
							onChange={(e) =>
								setState({...state, newsletter: e.target.checked})
							}
						/>
						<span className={styles.contactform.field.input()} />
						<span
							className={styles.contactform.field.label()}
							dangerouslySetInnerHTML={{
								__html: t.newsletter
							}}
						/>
					</label>
					<label className={styles.contactform.field.mod('checkbox')()}>
						<input
							type="checkbox"
							name="agreement"
							required
							checked={state.agreement}
							onChange={(e) =>
								setState({...state, agreement: e.target.checked})
							}
						/>
						<span className={styles.contactform.field.input()} />
						<span
							className={styles.contactform.field.label()}
							dangerouslySetInnerHTML={{
								__html: t.agreement
							}}
						/>
					</label>
					<Button
						as="button"
						iconafter="arrow_diagonal_small"
						className={styles.contactform.submit()}
					>
						{t.submit}
					</Button>
				</form>
			</Container>
		</Block>
	)
}
