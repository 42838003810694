import {Cases} from './cases/Cases'
import {Columns} from './columns/Columns'
import {Contactform} from './contactform/Contactform'
import {Contactpersons} from './contactpersons/Contactpersons'
import {ImageCta} from './imagecta/ImageCta'
import {ImageLinks} from './imagelinks/ImageLinks'
import {ImageText} from './imagetext/ImageText'
import {List} from './list/List'
import {Listdomains} from './listdomains/Listdomains'
import {Media} from './media/Media'
import {Partners} from './partners/Partners'
import {Text} from './text/Text'

export const cmsblocks = {
	cases: Cases,
	columns: Columns,
	contactform: Contactform,
	contactpersons: Contactpersons,
	imagecta: ImageCta,
	imagelinks: ImageLinks,
	imagetext: ImageText,
	list: List,
	listdomains: Listdomains,
	media: Media,
	partners: Partners,
	text: Text
}
