import {CMSNewsChannel} from 'cms-types'
import {NewsCard} from 'layout/cards/NewsCard'
import {Container} from 'layout/Container'
import {Hero} from 'layout/hero/Hero'
import {LinkStyled} from 'layout/LinkStyled'
import {useTranslation} from 'locale/LocaleContext'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './News.module.scss'

import type {CMSNewsChannelExtra} from './news.data'
const styles = fromModule(css)

export const News: React.FC<CMSNewsChannel & CMSNewsChannelExtra> = (node) => {
	const {title, news} = node
	const t = useTranslation()

	return (
		<div className={styles.news()}>
			<Hero title={title}>
				<LinkStyled
					to={t.social.linkedin.url}
					iconafter="arrow_diagonal"
					className={styles.news.social()}
				>
					{t.social.linkedin.description}
				</LinkStyled>
			</Hero>
			<div className={styles.news.items()}>
				<Container>
					{news?.length > 0 &&
						news.map((item, i) => (
							<div className={styles.news.items.item()} key={item.id}>
								<NewsCard {...item} key={i + item.title} />
							</div>
						))}
				</Container>
			</div>
		</div>
	)
}
