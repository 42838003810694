import {CMSWysiwyg} from 'cms-types'
import {Container} from 'layout/Container'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import NextImage from 'next/image'
import React from 'react'
import {useMediaQuery} from 'util/mediaquery'
import {fromModule} from 'util/styler/Styler'
import {Trusted} from 'util/trusted'
import css from './Herohome.module.scss'

const styles = fromModule(css)

export const Herohome: React.FC<{
	title: string
	text?: CMSWysiwyg
}> = ({title, text}) => {
	const isDesktop = useMediaQuery('(min-width: 1200px)')

	return (
		<div className={styles.herohome()}>
			{!isDesktop && (
				<div className={styles.herohome.wrapper()}>
					<NextImage
						priority
						src="/bg.jpg"
						alt="Background"
						layout="fill"
						objectFit="cover"
						objectPosition="center 0"
					/>
				</div>
			)}
			{isDesktop && (
				<div className={styles.herohome.wrapper.mod('video')()}>
					<video
						autoPlay
						muted
						loop
						className={styles.herohome.wrapper.video()}
					>
						<source src="/wave_fade.webm" type="video/webm" />
						<source src="/wave_fade.mp4" type="video/mp4" />
					</video>
				</div>
			)}

			<Container>
				<div className={styles.herohome.content()}>
					<Title.H1 className={styles.herohome.content.title()} mod="inherit">
						<Trusted>{title}</Trusted>
					</Title.H1>
					{text && (
						<Wysiwyg className={styles.herohome.content.text()}>{text}</Wysiwyg>
					)}
				</div>
			</Container>
		</div>
	)
}
