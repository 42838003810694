import {Blocks} from 'blocks/Blocks'
import {CMSPageChannel, CMSPartnersChannel} from 'cms-types'
import {Hero, HeroBack} from 'layout/hero/Hero'
import React from 'react'

export const Page: React.FC<CMSPageChannel | CMSPartnersChannel> = (node) => {
	const {title, description, image, parents, blocks} = node

	return (
		<div>
			<HeroBack parent={parents[0]} />
			<Hero title={title} text={description} image={image} />
			<Blocks blocks={blocks} />
		</div>
	)
}
