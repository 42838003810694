import React from 'react'
import {fromModule} from 'util/styler/Styler'
import {Trusted} from 'util/trusted'
import css from './Wysiwyg.module.scss'

const styles = fromModule(css)

export const Wysiwyg: React.FC<{className?: string; children: any}> = ({
	className,
	children
}) => {
	return (
		<div className={styles.wysiwyg.with('wysiwyg').with(className)()}>
			<Trusted>{children}</Trusted>
		</div>
	)
}
