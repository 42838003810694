import {useApp} from 'AppContext'
import {Block} from 'blocks/Block'
import {CMSImage, CMSLink, CMSPartnersBlock} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/LocaleContext'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {CMSPartnersBlockExtra} from './partners.data'
import css from './Partners.module.scss'

const styles = fromModule(css)

export const Partners: React.FC<CMSPartnersBlock & CMSPartnersBlockExtra> = ({
	title,
	partners,
	partnersPage
}) => {
	const {node} = useApp()
	const t = useTranslation()
	if (!partners || partners.length === 0) return null

	return (
		<Block type="partners" title={title} className={styles.partners()}>
			<Container>
				{title && (
					<Title.H2 className={styles.partners.title()} mod="uppercase">
						{title}
					</Title.H2>
				)}
				<div className={styles.partners.items()}>
					{partners.map((partner, i) => (
						<div key={i} className={styles.partners.items.item()}>
							<PartnerItem {...partner} />
						</div>
					))}
					{node?.channel !== 'partners' && (
						<div className={styles.partners.items.item.mod('link')()}>
							<LinkStyled
								to={partnersPage?.url}
								iconafter="arrow_diagonal"
								className={styles.partners.items.item.link()}
								mod={['fullwidth', 'large']}
							>
								{`${t.readmore}\n${title}`}
							</LinkStyled>
						</div>
					)}
				</div>
			</Container>
		</Block>
	)
}

const PartnerItem: React.FC<{
	title: string
	logo?: CMSImage
	link?: CMSLink
}> = ({title, logo, link}) => {
	return (
		<Link to={link?.url} title={title} className={styles.item()}>
			{logo?.src && (
				<Image
					{...logo}
					width={200}
					height={(200 * logo.height) / logo.width}
				/>
			)}
			{!logo?.src && <p>{title}</p>}
		</Link>
	)
}
