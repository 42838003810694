import React, {useState} from 'react'
import ReactPlayer from 'react-player'
import {fromModule} from 'util/styler/Styler'
import css from './Video.module.scss'

const styles = fromModule(css)

export const Video: React.FC<{video_url: string; className?: string}> = ({
	video_url,
	className
}) => {
	if (!video_url) return null
	const [isPlaying, setPlaying] = useState(false)

	return (
		<div
			className={styles.video.with(className)()}
			onClick={() => setPlaying(!isPlaying)}
		>
			<ReactPlayer
				url={video_url}
				playing={isPlaying}
				width={'100%'}
				height={'100%'}
				controls={true}
				style={{
					position: 'absolute',
					left: 0,
					right: 0,
					zIndex: 1
				}}
				playIcon={<span className={styles.video.play()} />}
			/>
		</div>
	)
}
