import {Block} from 'blocks/Block'
import {CMSCasesBlock, CMSImage, CMSLink, CMSWysiwyg} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Video} from 'layout/Video'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {fromModule} from 'util/styler/Styler'
import css from './Cases.module.scss'

const styles = fromModule(css)

type CasesItemType = {
	title: string
	image?: CMSImage
	video?: string
	text: CMSWysiwyg
	links?: CMSLink[]
}

export const Cases: React.FC<CMSCasesBlock> = ({title, text, items}) => {
	return (
		<Block type="cases" title={title}>
			<Container>
				<Title.H3 as="h2" className={styles.cases.title()} mod="uppercase">
					{title}
				</Title.H3>
				{text && <Wysiwyg className={styles.cases.text()}>{text}</Wysiwyg>}
				{items?.length > 0 && (
					<div className={styles.cases.items()}>
						{items.map((item, i) => (
							<CasesItem {...item} key={i} />
						))}
					</div>
				)}
			</Container>
		</Block>
	)
}

const CasesItem: React.FC<CasesItemType> = ({
	title,
	image,
	video,
	text,
	links
}) => {
	const [isOpen, setOpen] = useState(false)

	return (
		<div className={styles.item()}>
			<a onClick={() => setOpen(!isOpen)} className={styles.item.titlerow()}>
				<Title.H5 as="h3" mod="uppercase">
					{title}
				</Title.H5>
				<span className={styles.item.titlerow.icon.is({open: isOpen})()} />
			</a>
			<AnimateHeight height={isOpen ? 'auto' : 0}>
				<div className={styles.item.content()}>
					<div className={styles.item.content.media()}>
						{image?.src && !video && <Image {...image} />}
						{video && <Video video_url={video} />}
					</div>
					{text && (
						<Wysiwyg className={styles.item.content.text()}>{text}</Wysiwyg>
					)}
					{links?.length > 0 && (
						<div className={styles.item.content.links()}>
							{links.map(
								(link, i) =>
									link?.url && (
										<LinkStyled
											key={i}
											to={link.url}
											iconafter="arrow_diagonal"
											className={styles.item.content.links.link()}
										>
											{link.description}
										</LinkStyled>
									)
							)}
						</div>
					)}
				</div>
			</AnimateHeight>
		</div>
	)
}
