import {Blocks} from 'blocks/Blocks'
import {CMSDomain_detailChannel} from 'cms-types'
import {Hero, HeroBack} from 'layout/hero/Hero'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './DomainDetail.module.scss'

const styles = fromModule(css)

export const DomainDetail: React.FC<CMSDomain_detailChannel> = (node) => {
	const {title, description, parents, hero_title, hero_text, blocks} = node

	return (
		<div className={styles.domaindetail()}>
			<HeroBack parent={parents[0]} />
			<Hero title={hero_title || title} text={hero_text || description} />
			<Blocks blocks={blocks} />
		</div>
	)
}
