import {Block} from 'blocks/Block'
import {CMSImage, CMSListBlock, CMSWysiwyg} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {fromModule} from 'util/styler/Styler'
import css from './List.module.scss'
import {EntryOverview} from 'layout/EntryOverview'
import React from 'react'
import { CMSListBlockExtra } from './list.data'
import { useTranslation } from 'locale/LocaleContext'

const styles = fromModule(css)

type ListItemType = {
	title: string
	text?: CMSWysiwyg
	add_image?: boolean
	image?: CMSImage
}

export const List: React.FC<CMSListBlock & CMSListBlockExtra> = ({
	three_quarter,
	title,
	text,
	items,
	domains,
	link
}) => {
	return (
		<Block type="list" title={title} className={styles.list()}>
			<Container>
				{title && (
					<Title.Wrapper className={styles.list.title()}>
						<Title.H2 mod={['dots', 'uppercase']}>{title}</Title.H2>
					</Title.Wrapper>
				)}
				{text && <Wysiwyg className={styles.list.text()}>{text}</Wysiwyg>}
				{items?.length > 0 && (
					<div className={styles.list.items()}>
						{items.map((item, i) => (
							<ListItem key={i} three_quarter={three_quarter} {...item} />
						))}
					</div>
				)}
				{domains?.length > 0 && (
					<ListDomains domains={domains} />
				)}
				{link?.url && (
					<LinkStyled
						to={link.url}
						iconafter="arrow_diagonal"
						className={styles.list.link()}
						mod={['large']}
					>
						{link.description}
					</LinkStyled>
				)}
			</Container>
		</Block>
	)
}

const ListDomains: React.FC<{
	domains
}> = ({domains}) => {
	if (!domains || domains.length === 0) return null
	const t = useTranslation()

	return (
		<div className={styles.domains()}>
			<div className={styles.domains.description()}>{t.domains.description}</div>
			<EntryOverview items={domains} skipLinks/>
		</div>
	)
}

const ListItem: React.FC<
	ListItemType & {
		three_quarter?: boolean
	}
> = ({three_quarter, title, text, add_image, image}) => {
	return (
		<div
			className={styles.item.mod({three_quarter}).mod({
				image: add_image && image?.src
			})()}
		>
			{add_image && image?.src && (
				<div className={styles.item.image()}>
					<Image {...image} />
				</div>
			)}
			<div className={styles.item.content()}>
				<Title.H5 className={styles.item.content.title()}>{title}</Title.H5>
				{text && <Wysiwyg>{text}</Wysiwyg>}
			</div>
		</div>
	)
}
