import {Block} from 'blocks/Block'
import {CMSMediaBlock} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Video} from 'layout/Video'
import {fromModule} from 'util/styler/Styler'
import css from './Media.module.scss'

const styles = fromModule(css)

export const Media: React.FC<CMSMediaBlock> = ({image, video}) => {
	if (!image?.src && !video) return null

	return (
		<Block type="media">
			<Container>
				<div className={styles.media.wrapper()}>
					{image?.src && !video && <Image {...image} />}
					{video && <Video video_url={video} />}
				</div>
			</Container>
		</Block>
	)
}
