import {CMSAiresearchBlock} from 'cms-types'
import {slugify} from 'util/slugify'
import {styler} from 'util/styler/Styler'

const styles = styler('block')

export const Block: React.FC<
	{
		type: CMSAiresearchBlock['type']
		className?: string
		style?: any
	} & ({title?: string} | {id?: string})
> = ({type, className, style, children, ...rest}) => {
	let id = undefined
	if ('title' in rest) id = slugify(rest.title)
	if ('id' in rest) id = rest.id

	return (
		<div id={id} className={styles.with(type).with(className)()} style={style}>
			{children}
		</div>
	)
}
