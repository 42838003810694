import {Block} from 'blocks/Block'
import {CMSColumnsBlock, CMSLink, CMSWysiwyg} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import React, {Fragment} from 'react'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Columns.module.scss'

const styles = fromModule(css)

export const Columns: React.FC<CMSColumnsBlock> = ({image, items}) => {
	if (!image?.src && (!items || items.length === 0)) return null

	return (
		<Block type="columns" className={styles.columns()}>
			<Container>
				<div className={styles.columns.items()}>
					{image?.src && (
						<div className={styles.columns.items.item.mod('image')()}>
							<Image {...image} className={styles.columns.items.item.image()} />
						</div>
					)}
					{items?.length > 0 &&
						items.map((item, i) => (
							<div key={i} className={styles.columns.items.item()}>
								<ColumnsItem {...item} />
							</div>
						))}
				</div>
			</Container>
		</Block>
	)
}

const ColumnsItem: React.FC<{
	title?: string
	text?: CMSWysiwyg
	link?: CMSLink & {title?: string}
}> = ({title, text, link}) => {
	const t = useTranslation()
	const LinkTag = link?.url ? Link : Fragment
	const linksProps = link?.url ? {to: link?.url} : {}

	return (
		<div className={styles.item()}>
			<div className={styles.item.content()}>
				{(title || link?.title) && (
					<Title.H3>
						<LinkTag {...linksProps}>{title || link?.title}</LinkTag>
					</Title.H3>
				)}
				{text && (
					<Wysiwyg className={styles.item.content.text()}>{text}</Wysiwyg>
				)}
			</div>
			{link?.url && (
				<LinkStyled
					to={link.url}
					iconafter="arrow_diagonal"
					className={styles.item.link()}
					mod={['large', 'fullwidth']}
				>
					{link?.description}
				</LinkStyled>
			)}
		</div>
	)
}
