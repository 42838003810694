import {GetStaticPaths, GetStaticProps} from 'next'
import {
	fetchAllPageNodes,
	fetchAllPossibleUrls,
	fetchNodeData
} from 'server/node'

import {AppEnvType} from 'AppContext'
import {CMSAiresearchChannel} from 'cms-types'
import {DomainDetail} from 'channels/domain_detail/DomainDetail'
import {Domains} from 'channels/domains/Domains'
import {Events} from 'channels/events/Events'
import {Home} from 'channels/home/Home'
import {Library} from 'channels/library/Library'
import {News} from 'channels/news/News'
import {NewsDetail} from 'channels/news_detail/NewsDetail'
import {Page} from 'channels/page/Page'
import {Research} from 'channels/research/Research'
import {ResearchDetail} from 'channels/research_detail/ResearchDetail'
import {Team} from 'channels/team/Team'
import {Toolbox} from 'channels/toolbox/Toolbox'
import {ToolboxDetail} from 'channels/toolbox_detail/ToolboxDetail'
import {fetchGeneralData} from 'server/general'

export const CMSPage: React.FC<CMSAiresearchChannel> = (data) => {
	switch (data.channel) {
		case 'home':
			return <Home {...data} />
		case 'page':
		case 'partners':
			return <Page {...data} />
		case 'research':
			return <Research {...data} />
		case 'research_detail':
			return <ResearchDetail {...data} />
		case 'domains':
			return <Domains {...data} />
		case 'domain_detail':
			return <DomainDetail {...data} />
		case 'events':
			return <Events {...data} />
		case 'news':
			return <News {...data} />
		case 'news_detail':
			return <NewsDetail {...data} />
		case 'library':
			return <Library {...data} />
		case 'toolbox':
			return <Toolbox {...data} />
		case 'toolbox_detail':
			return <ToolboxDetail {...data} />
		case 'team':
			return <Team {...data} />
		default:
			throw new Error(
				`Unknown channel: ${(data as CMSAiresearchChannel).channel}`
			)
	}
}

export default CMSPage

export const getStaticPaths: GetStaticPaths = async () => {
	let pages = []

	if (process.env.PRELOAD) {
		pages = await fetchAllPageNodes()
	}

	return {
		fallback: 'blocking',
		paths: pages.map(({url}) => ({
			params: {slug: url.split('/').slice(1)}
		}))
	}
}

export const getStaticProps: GetStaticProps = async (context) => {
	const pieces = context.params!.slug as string[]
	const url = '/' + pieces.join('/')
	const slug = pieces.length > 1 ? '/' + pieces.slice(1).join('/') : ''
	const lang = pieces[0] || 'nl'

	//Optimization to prevent 404 pages from triggering an expensive DB query
	const urls = await fetchAllPossibleUrls()
	if (!new Set(urls).has(url)) {
		return {notFound: true}
	}

	const general = await fetchGeneralData(lang)
	let node = await fetchNodeData(
		lang,
		slug,
		context.preview ? (context.previewData as {token: string}).token : null
	)

	if (!node) {
		return {notFound: true}
	}

	if (typeof node === 'string') {
		return {
			redirect: {
				destination: node,
				permanent: true
			},
			revalidate: 2 * 60
		}
	}

	// Akward JSON parse/stringify because of https://github.com/vercel/next.js/discussions/11209
	node = JSON.parse(JSON.stringify(node))

	return {
		props: {
			node,
			general,
			app_env: (process.env.APP_ENV as AppEnvType) || null
		},
		revalidate: 10
	}
}
