import {useRouter} from 'next/router'
import React, {FunctionComponent, useEffect, useRef} from 'react'
import {checkIsExternal, checkIsFile, scrollToHash} from './link'

const click = (router, e) => {
	if (
		e.defaultPrevented ||
		e.button !== 0 ||
		e.altKey ||
		e.metaKey ||
		e.ctrlKey ||
		e.shiftKey ||
		e.currentTarget.target === '_blank' ||
		checkIsExternal(e.currentTarget.hostname) ||
		e.target.protocol === 'mailto:'
	)
		return
	e.preventDefault()
	const href = e.currentTarget.getAttribute('href')

	if (href.substring(0, 1) === '#') {
		scrollToHash(href)
	} else {
		router.push(href)
	}
}

const removeQueryParams = (url) =>
	url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url

export const Trusted: FunctionComponent<{
	children: string
}> = ({children, ...props}) => {
	const router = useRouter()
	const dom = useRef<HTMLDivElement>()

	useEffect(() => {
		const clear = []
		const listener = click.bind(router)

		dom.current.querySelectorAll('a').forEach((link) => {
			const href = link.getAttribute('href')
			if (!href) return
			const hrefWithoutQuery = removeQueryParams(href)
			if (checkIsFile(hrefWithoutQuery) || checkIsExternal(href)) {
				link.setAttribute('target', '_blank')
				link.setAttribute('rel', 'external nofollow noopener')
			}
			link.addEventListener('click', (e) => click(router, e))
			clear.push(() => link.removeEventListener('click', listener))
		})

		return () => {
			clear.forEach((f) => f())
		}
	})

	return (
		<div
			ref={dom}
			{...props}
			dangerouslySetInnerHTML={{
				__html: Array.isArray(children) ? children[0] : children
			}}
		/>
	)
}
