import {format, parseISO} from 'date-fns'

export const parseDate = (date: Date | string) =>
	date instanceof Date ? date : parseISO(date)

export function formatDayMonthYear(date: Date | string, dateLocale: Locale) {
	return format(parseDate(date), 'dd-MM-yyyy', {
		locale: dateLocale
	})
}
