import {CMSAiresearchBlock} from 'cms-types'
import {styler} from 'util/styler/Styler'
import {cmsblocks} from './cmsblocks'

const styles = styler('blocks')

export const Blocks: React.FC<{blocks: CMSAiresearchBlock[]}> = ({blocks}) => {
	if (!blocks || blocks.length === 0) return null

	return (
		<div className={styles()}>
			{blocks.map((block, i) => (
				<CMSBlock block={block} key={i} />
			))}
		</div>
	)
}

export const CMSBlock: React.FC<{block: CMSAiresearchBlock}> = ({block}) => {
	const Component: React.FC<CMSAiresearchBlock> = cmsblocks[block.type]
	if (!Component) {
		return null
	}
	return <Component {...block} />
}
