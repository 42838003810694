import {Carousel, useCarousel} from '@codeurs/carousel'
import {Blocks} from 'blocks/Blocks'
import {CMSDate, CMSHomeChannel, CMSImage, CMSLink} from 'cms-types'
import {Container} from 'layout/Container'
import {Herohome} from 'layout/herohome/Herohome'
import {Icon} from 'layout/Icon'
import {Image} from 'layout/Image'
import {useTranslation} from 'locale/LocaleContext'
import React from 'react'
import {formatDayMonthYear} from 'util/dates'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {CMSHomeChannelExtra} from './home.data'
import css from './Home.module.scss'

const styles = fromModule(css)

export const Home: React.FC<CMSHomeChannel & CMSHomeChannelExtra> = (node) => {
	const {
		title,
		herohome_title,
		herohome_text,
		highlighted_events,
		highlighted_news,
		blocks
	} = node

	return (
		<div className={styles.home()}>
			<Herohome title={herohome_title || title} text={herohome_text} />
			<HomeCarousel
				highlighted_events={highlighted_events}
				highlighted_news={highlighted_news}
			/>
			<Blocks blocks={blocks} />
		</div>
	)
}

const HomeCarousel: React.FC<CMSHomeChannelExtra> = ({
	highlighted_events,
	highlighted_news
}) => {
	if (highlighted_events?.length === 0 && highlighted_news?.length === 0)
		return null

	const carousel = useCarousel()
	const l = Math.min(highlighted_events?.length, highlighted_news?.length)
	let highlighted = []
	for (let i = 0; i < l; i++) {
		highlighted.push(highlighted_events[i], highlighted_news[i])
	}
	highlighted.push(...highlighted_events.slice(l), ...highlighted_news.slice(l))

	return (
		<div className={styles.carousel()}>
			<Container>
				<div className={styles.carousel.slides()}>
					<Carousel full {...carousel}>
						{highlighted.map((slide, i) => (
							<div
								key={i}
								className={styles.carousel.slides.slide.mod(slide.channel)()}
							>
								<Card {...slide} />
							</div>
						))}
					</Carousel>
				</div>
				<div className={styles.carousel.nav()}>
					{carousel.hasPrevious() && (
						<a
							onClick={() => carousel.goPrevious()}
							className={styles.carousel.nav.prev()}
						>
							<span className={styles.carousel.nav.prev.icon()}>
								<Icon icon="arrow_left" />
							</span>
						</a>
					)}
					{carousel.hasNext() && (
						<a
							onClick={() => carousel.goNext()}
							className={styles.carousel.nav.next()}
						>
							<span className={styles.carousel.nav.next.icon()}>
								<Icon icon="arrow_right" />
							</span>
						</a>
					)}
				</div>
			</Container>
		</div>
	)
}

type CardType = {
	channel: 'event_item' | 'news_detail' | 'news_item'
	url: string
	title: string
	image?: CMSImage
	date?: CMSDate
	link?: CMSLink
}

export const Card: React.FC<CardType> = ({
	channel,
	url: entryUrl,
	title,
	image,
	date,
	link
}) => {
	const url = channel === 'news_detail' ? entryUrl : link?.url
	if (!url) return null
	const t = useTranslation()

	return (
		<Link to={url} className={styles.card()}>
			{image?.src && (
				<Image {...image} as="background" className={styles.card.image()} />
			)}
			<span className={styles.card.overlay()} />
			<span className={styles.card.diagonal()}>
				<Icon icon="arrow_diagonal" />
			</span>
			{channel !== 'news_item' && (
				<span className={styles.card.icon()}>
					<Icon icon={channel === 'event_item' ? 'calendar' : 'newspaper'} />
				</span>
			)}
			<div className={styles.card.content()}>
				{date && (
					<p className={styles.card.content.date()}>
						{formatDayMonthYear(date, t.dateLocale)}
					</p>
				)}
				<h4 className={styles.card.content.title()}>{title}</h4>
			</div>
		</Link>
	)
}
