import {Blocks} from 'blocks/Blocks'
import {CMSNews_detailChannel} from 'cms-types'
import {Hero, HeroBack} from 'layout/hero/Hero'
import {LinkStyled} from 'layout/LinkStyled'
import {useTranslation} from 'locale/LocaleContext'
import React, {useEffect, useState} from 'react'
import {facebookLink, linkedInLink, twitterLink} from 'util/share'
import {fromModule} from 'util/styler/Styler'
import css from './NewsDetail.module.scss'

const styles = fromModule(css)

export const NewsDetail: React.FC<CMSNews_detailChannel> = (node) => {
	const {title, image, description, parents, blocks} = node
	const t = useTranslation()
	const [url, setUrl] = useState(null)

	useEffect(() => {
		setUrl(window.location.href.split(/[?#]/)[0])
	}, [])

	return (
		<div className={styles.newsdetail()}>
			<HeroBack parent={parents[1]} label={t.news.overview} />
			<Hero title={title} text={description} image={image}>
				<div className={styles.newsdetail.social()}>
					<LinkStyled
						{...linkedInLink(url)}
						iconafter="arrow_diagonal"
						className={styles.newsdetail.social.link()}
					>
						{t.share + `\n LinkedIn`}
					</LinkStyled>
					<LinkStyled
						{...twitterLink('', url)}
						iconafter="arrow_diagonal"
						className={styles.newsdetail.social.link()}
					>
						{t.share + `\n Twitter`}
					</LinkStyled>
					<LinkStyled
						{...facebookLink(url)}
						iconafter="arrow_diagonal"
						className={styles.newsdetail.social.link()}
					>
						{t.share + `\n Facebook`}
					</LinkStyled>
				</div>
			</Hero>
			<Blocks blocks={blocks} />
		</div>
	)
}
