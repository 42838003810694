import {Block} from 'blocks/Block'
import {CMSImagectaBlock, CMSLink, CMSWysiwyg} from 'cms-types'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {getImageRatio, Image} from 'layout/Image'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {fromModule} from 'util/styler/Styler'
import css from './ImageCta.module.scss'

const styles = fromModule(css)

export const ImageCta: React.FC<CMSImagectaBlock> = ({
	title,
	image,
	text,
	cta
}) => {
	if (!image?.src) return null

	return (
		<Block type="imagecta" title={title} className={styles.imagecta()}>
			<Container>
				{title && (
					<Title.Wrapper className={styles.imagecta.title()} mod="large">
						<Title.H2 mod={['dots', 'uppercase']}>{title}</Title.H2>
					</Title.Wrapper>
				)}
				<div className={styles.imagecta.row()}>
					<Image
						{...image}
						layout="responsive"
						width={1280}
						height={getImageRatio(1280, image)}
						className={styles.imagecta.image()}
					/>
					<ImageCtaContent text={text} cta={cta} />
				</div>
			</Container>
		</Block>
	)
}

const ImageCtaContent: React.FC<{
	text: CMSWysiwyg
	cta: CMSLink
}> = ({text, cta}) => {
	if (!text && !cta?.url) return null

	return (
		<div className={styles.content()}>
			{text && <Wysiwyg className={styles.content.text()}>{text}</Wysiwyg>}
			{cta?.url && (
				<Button
					to={cta.url}
					iconafter="arrow_diagonal_small"
					className={styles.content.cta()}
				>
					{cta.description}
				</Button>
			)}
		</div>
	)
}
