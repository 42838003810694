import {
	CMSToolbox_detailChannel,
	CMSToolbox_itemChannel,
	CMSToolboxChannel
} from 'cms-types'
import {Container} from 'layout/Container'
import {DropdownStyled} from 'layout/Dropdown'
import {Hero} from 'layout/hero/Hero'
import {Icon} from 'layout/Icon'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import {useRouter} from 'next/router'
import React from 'react'
import {Link} from 'util/link'
import {Variant} from 'util/styler'
import {fromModule} from 'util/styler/Styler'
import {CMSToolboxChannelExtra} from './toolbox.data'
import css from './Toolbox.module.scss'

const styles = fromModule(css)
const itemsPerPage = 15

const useToolboxRouter = () => {
	const router = useRouter()
	const shallowPush = (args: Record<string, string | number | string[]>) => {
		return router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					...args
				}
			},
			undefined,
			{shallow: true}
		)
	}

	const categoryParam = router.query.category
	let category: string[] = []
	if (categoryParam && typeof categoryParam === 'string')
		category = [categoryParam]
	if (Array.isArray(categoryParam)) category = categoryParam

	return {
		page: parseInt(router.query.page as string, 10) || 0,
		setPage: (page) => shallowPush({page}),
		selectedCategories: category,
		categoriesSet: new Set(category),
		addCategory: (extraCategory: string) =>
			shallowPush({
				category: [...category, extraCategory],
				page: 0
			}),
		removeCategory: (removedCategory: string) =>
			shallowPush({
				category: category.filter((val) => val !== removedCategory),
				page: 0
			}),
		clear: () => shallowPush({category: null, page: 0})
	}
}

export const Toolbox: React.FC<CMSToolboxChannel & CMSToolboxChannelExtra> = (
	node
) => {
	const t = useTranslation()
	const {title, description, children, options_categories} = node
	const {selectedCategories, page} = useToolboxRouter()
	const selectedCategoriesSet = new Set(selectedCategories)

	const filteredChildren = children.filter((child) => {
		if (selectedCategories?.length === 0) return true
		return child.categories.find((category: {title: string; path: string}) =>
			selectedCategoriesSet.has(category.path)
		)
	})
	const visibleChildren = filteredChildren.filter(
		(_, i) => i >= page * itemsPerPage && i < (page + 1) * itemsPerPage
	)
	const pages = Math.ceil(filteredChildren.length / itemsPerPage)

	return (
		<div className={styles.toolbox()}>
			<Hero title={title} text={description} />
			<Container>
				<ToolboxFilters options_categories={options_categories} />
				<div className={styles.toolbox.head()}>
					<ToolboxColumn mod="small">{t.toolbox.title}</ToolboxColumn>
					<ToolboxColumn mod={['maxwidth', 'small']}>
						{t.toolbox.description}
					</ToolboxColumn>
					<ToolboxColumn mod={['minwidth', 'small']}>
						{t.toolbox.categories}
					</ToolboxColumn>
					<ToolboxColumn mod={['minwidth', 'small']}>
						{t.toolbox.link}
					</ToolboxColumn>
				</div>
				{visibleChildren.map((child, i) => (
					<ToolboxItem {...child} key={`${child.id}|${i}`} />
				))}
				{visibleChildren?.length === 0 && (
					<p className={styles.toolbox.empty()}>{t.toolbox.empty}</p>
				)}
				{pages > 1 && <ToolboxPagination pages={pages} />}
			</Container>
		</div>
	)
}

const ToolboxFilters: React.FC<{
	options_categories: {title: string; path: string}[]
}> = ({options_categories}) => {
	const t = useTranslation()
	const optionsCategories = options_categories.map(function (o) {
		return {value: o.path, label: o.title}
	})
	const toolboxRouter = useToolboxRouter()

	return (
		<div className={styles.filters()}>
			<div className={styles.filters.top()}>
				<div>
					<div
						className={styles.filters.item()}
						key={toolboxRouter.selectedCategories.join('|')}
					>
						<DropdownStyled
							options={optionsCategories.filter(
								(opt) => !toolboxRouter.categoriesSet.has(opt.value)
							)}
							onChange={(e) => toolboxRouter.addCategory(e.value)}
							value={null}
							placeholder={t.toolbox.categories}
						/>
					</div>
				</div>
				{toolboxRouter.selectedCategories?.length > 0 && (
					<div>
						<LinkStyled
							onClick={() => toolboxRouter.clear()}
							className={styles.filters.clear()}
						>
							{t.toolbox.clear}
						</LinkStyled>
					</div>
				)}
			</div>
			<div className={styles.filters.selected()}>
				{toolboxRouter.selectedCategories.map((value) => (
					<ToolboxTag
						label={options_categories.find((opt) => opt.path === value)?.title}
						value={value}
						key={value}
					/>
				))}
			</div>
		</div>
	)
}

const ToolboxTag: React.FC<{
	label: string
	value: string
}> = ({value, label}) => {
	const {removeCategory} = useToolboxRouter()

	return (
		<div className={styles.tag()} onClick={() => removeCategory(value)}>
			<div className={styles.tag.content()}>{label}</div>
			<span className={styles.tag.icon()}>
				<Icon icon="close" />
			</span>
		</div>
	)
}

const ToolboxPagination: React.FC<{pages: number}> = ({pages}) => {
	const {page, setPage} = useToolboxRouter()

	return (
		<div className={styles.pagination()}>
			{Array.from({length: pages}).map((_, i) => (
				<a
					className={styles.pagination.item.is({active: i === page})()}
					onClick={() => setPage(i)}
					key={i}
				>
					{i + 1}
				</a>
			))}
		</div>
	)
}

const ToolboxColumn: React.FC<{
	mod?: Variant<'small' | 'minwidth' | 'maxwidth'>
}> = ({mod, children}) => {
	return <div className={styles.column.mod(mod)()}>{children}</div>
}

const ToolboxItem: React.FC<
	| ({
			channel: 'toolbox_detail'
			link?: undefined
			categories: {title: string; path: string}[]
	  } & CMSToolbox_detailChannel)
	| ({
			channel: 'toolbox_item'
			categories: {title: string; path: string}[]
	  } & CMSToolbox_itemChannel)
> = ({channel, url, title, description, categories, link}) => {
	if (!title) return null
	const t = useTranslation()
	const linkUrl = channel === 'toolbox_item' ? link?.url : url
	const linkLabel =
		channel === 'toolbox_detail'
			? title
			: link?.type === 'file'
			? t.toolbox.download
			: link?.description

	return (
		<div className={styles.item()}>
			<ToolboxColumn>
				<Title.H5 mod="inherit">
					<Link to={linkUrl}>{title}</Link>
				</Title.H5>
			</ToolboxColumn>
			<ToolboxColumn mod={['maxwidth']}>
				<div className={styles.item.content()}>
					{description && (
						<Wysiwyg className={styles.item.content.text()}>
							{description}
						</Wysiwyg>
					)}
				</div>
			</ToolboxColumn>
			<ToolboxColumn mod="minwidth">
				{categories?.length > 0 && (
					<p>{categories.map((category) => category.title).join(', ')}</p>
				)}
			</ToolboxColumn>
			<ToolboxColumn mod="minwidth">
				<p></p>
				{linkUrl && (
					<LinkStyled to={linkUrl} iconafter="arrow_diagonal" mod="minwidth">
						{linkLabel}
					</LinkStyled>
				)}
			</ToolboxColumn>
		</div>
	)
}
