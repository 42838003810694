import {EntryCard, EntryCardType} from 'layout/cards/EntryCard'
import {Container} from 'layout/Container'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './EntryOverview.module.scss'

const styles = fromModule(css)

export const EntryOverview: React.FC<{
	items?: Array<EntryCardType>
	skipLinks?: boolean
}> = ({items, skipLinks}) => {
	if (!items || items.length === 0) return null

	return (
		<div className={styles.entryoverview.mod({compact: skipLinks})()}>
			<Container>
				<div className={styles.entryoverview.items()}>
					{items.map((item) => (
						<div key={item.url} className={styles.entryoverview.items.item()}>
							{<EntryCard {...item} skipLinks={skipLinks} />}
						</div>
					))}
				</div>
			</Container>
		</div>
	)
}
