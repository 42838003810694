import {CMSEvent_itemChannel, CMSEventsChannel} from 'cms-types'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Hero} from 'layout/hero/Hero'
import {Icon} from 'layout/Icon'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import React, {useState} from 'react'
import {formatDayMonthYear} from 'util/dates'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Events.module.scss'

import type {CMSEventsChannelExtra} from './events.data'
const styles = fromModule(css)

export const Events: React.FC<CMSEventsChannel & CMSEventsChannelExtra> = (
	node
) => {
	const {title, events_future, events_past} = node
	const t = useTranslation()
	const loadEvents = 3
	const [useMaxEvents, setMaxEvents] = useState<number>(loadEvents)

	return (
		<div className={styles.events()}>
			<Hero title={title}>
				<LinkStyled
					to={t.social.linkedin.url}
					iconafter="arrow_diagonal"
					className={styles.events.social()}
				>
					{t.social.linkedin.description}
				</LinkStyled>
			</Hero>
			<div className={styles.events.content()}>
				<Container>
					{events_future?.length > 0 && (
						<div className={styles.events.content.items()}>
							{events_future.map((item, i) => (
								<EventItem {...item} key={i + item.title} />
							))}
						</div>
					)}
					{events_future?.length === 0 && (
						<p className={styles.events.content.empty()}>
							{t.events.empty_events}
						</p>
					)}
					<Title.H3 className={styles.events.content.title()} mod="uppercase">
						{t.events.past_events}
					</Title.H3>
					{events_past?.length > 0 && (
						<div className={styles.events.content.items()}>
							{events_past.slice(0, useMaxEvents).map((item, i) => (
								<EventItem {...item} key={i + item.title} />
							))}
						</div>
					)}
					{useMaxEvents < events_past.length && (
						<div className={styles.events.content.loadmore()}>
							<Button
								mod="outline"
								onClick={() => setMaxEvents(useMaxEvents + loadEvents)}
							>
								{t.loadmore}
							</Button>
						</div>
					)}
				</Container>
			</div>
		</div>
	)
}

const EventItem: React.FC<CMSEvent_itemChannel> = ({
	title,
	date,
	link,
	text
}) => {
	if (!title && !date) return null
	const t = useTranslation()

	return (
		<div className={styles.eventitem()}>
			{date && <p>{formatDayMonthYear(date, t.dateLocale)}</p>}
			<Link to={link?.url} className={styles.eventitem.titlerow()}>
				<Title.H4 as="h3">{title}</Title.H4>
				<span className={styles.eventitem.titlerow.icon()}>
					<Icon icon="arrow_diagonal" />
				</span>
			</Link>
			{text && <Wysiwyg className={styles.eventitem.text()}>{text}</Wysiwyg>}
		</div>
	)
}
