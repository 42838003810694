import {
	CMSTeamChannel,
	CMSTeam_categoryChannel,
	CMSTeam_itemChannel
} from 'cms-types'
import {Hero, HeroBack} from 'layout/hero/Hero'

import {CMSTeamChannelExtra} from './team.data'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import React from 'react'
import {Title} from 'layout/Title'
import {Trusted} from 'util/trusted'
import css from './Team.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const Team: React.FC<CMSTeamChannel & CMSTeamChannelExtra> = (node) => {
	const {title, parents, team} = node
	if (team?.length === 0) return null

	console.log(team)

	return (
		<div className={styles.team()}>
			<HeroBack parent={parents[0]} />
			<Hero title={title} />
			<div className={styles.team.content()}>
				<Container>
					{team.map((category, i) => (
						<TeamCategory key={i} {...(category as any)} />
					))}
				</Container>
			</div>
		</div>
	)
}

const TeamCategory: React.FC<CMSTeam_categoryChannel & {children: any}> = ({
	title,
	children
}) => {
	if (children.length === 0) return null

	return (
		<div className={styles.category()}>
			<Title.H2 className={styles.category.title()}>{title}</Title.H2>
			<div className={styles.category.items()}>
				{children.map((child) => (
					<TeamItem key={child.url} {...child} />
				))}
			</div>
		</div>
	)
}

const TeamItem: React.FC<CMSTeam_itemChannel> = ({
	image,
	title,
	description,
	email
}) => {
	const t = useTranslation()

	return (
		<div className={styles.item()}>
			<div className={styles.item.image()}>
				{image?.src && <Image {...image} as="square" />}
			</div>
			<Title.H3>{title}</Title.H3>
			{description && (
				<p className={styles.item.description()}>
					<Trusted>{description}</Trusted>
				</p>
			)}
			{email && (
				<LinkStyled
					to={`mailto:${email}`}
					iconafter="arrow_diagonal"
					className={styles.item.email()}
					mod={['small']}
				>
					{t.team.email}
				</LinkStyled>
			)}
		</div>
	)
}
