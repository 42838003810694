import {CMSAiresearchChannel, CMSImage, CMSWysiwyg} from 'cms-types'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './Hero.module.scss'

const styles = fromModule(css)

export const Hero: React.FC<{
	title: string
	text?: CMSWysiwyg
	image?: CMSImage
}> = ({title, text, image, children}) => {
	const hasImage = image?.src

	return (
		<div className={styles.hero()}>
			<Container>
				<div className={styles.hero.titlerow()}>
					<Title.Wrapper
						className={styles.hero.titlerow.title.mod({image: hasImage})()}
					>
						<Title.H1 mod={['dots', 'uppercase']}>{title}</Title.H1>
					</Title.Wrapper>

					{children && (
						<div className={styles.hero.titlerow.children()}>{children}</div>
					)}
				</div>
				{!hasImage && text && (
					<div className={styles.hero.content()}>
						<Wysiwyg>{text}</Wysiwyg>
					</div>
				)}
				{hasImage && (
					<div className={styles.hero.row()}>
						<Wysiwyg className={styles.hero.row.text()}>{text}</Wysiwyg>
						<div className={styles.hero.row.image()}>
							<Image {...image} className={styles.hero.row.image.img()} />
						</div>
					</div>
				)}
			</Container>
		</div>
	)
}

export const HeroBack: React.FC<{
	parent?: CMSAiresearchChannel
	label?: string
}> = ({parent, label}) => {
	if (!parent?.url) return null

	return (
		<div className={styles.back()}>
			<Container>
				<LinkStyled
					to={parent.url}
					iconbefore="arrow_left"
					mod={['aligncenter', 'small']}
				>
					{label || parent.title}
				</LinkStyled>
			</Container>
		</div>
	)
}
