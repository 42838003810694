import {Block} from 'blocks/Block'
import {CMSContactpersonsBlock} from 'cms-types'
import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './Contactpersons.module.scss'

const styles = fromModule(css)

export const Contactpersons: React.FC<CMSContactpersonsBlock> = ({
	title,
	text,
	link,
	items
}) => {
	const t = useTranslation()

	return (
		<Block type="contactpersons" title={title}>
			<Container>
				{title && (
					<Title.H2 className={styles.contactpersons.title()} mod="uppercase">
						{title}
					</Title.H2>
				)}
				{text && (
					<Wysiwyg className={styles.contactpersons.text()}>{text}</Wysiwyg>
				)}
				{link?.url && (
					<LinkStyled
						to={link.url}
						iconafter="arrow_diagonal"
						className={styles.contactpersons.link()}
						mod="large"
					>
						{t.knowmore + `\n` + link.description}
					</LinkStyled>
				)}
				{items?.length > 0 && (
					<div className={styles.contactpersons.items()}>
						{items.map((item, i) => (
							<div key={i} className={styles.contactpersons.items.item()}>
								<div className={styles.contactpersons.items.item.column()}>
									{item.person}
								</div>
								<div className={styles.contactpersons.items.item.column()}>
									{item?.link?.url && (
										<LinkStyled
											to={item.link.url}
											iconafter="arrow_diagonal"
											className={styles.contactpersons.items.item.link()}
											mod="fullwidth"
										>
											{item.link.description}
										</LinkStyled>
									)}
								</div>
								<div className={styles.contactpersons.items.item.column()}>
									{item.description && <Wysiwyg>{item.description}</Wysiwyg>}
								</div>
							</div>
						))}
					</div>
				)}
			</Container>
		</Block>
	)
}
