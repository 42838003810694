import {CMSImage} from 'cms-types'
import NextImage, {ImageProps} from 'next/image'
import {fromModule} from 'util/styler/Styler'
import css from './Image.module.scss'

const styles = fromModule(css)

type AsType = {
	as?: 'background' | 'landscape' | 'square'
}

export const Image: React.FC<
	{className?: string} & AsType & CMSImage & ImageProps
> = ({as, className, ...image}) => {
	if (!image.src) return null

	return (
		<div className={styles.image.mod(as).with(className)()}>
			<NextImage
				priority
				{...switchProps(image, as)}
				focus={undefined}
				meta={undefined}
				title={undefined}
				alt={image?.alt || image?.title}
				src={image.src}
			/>
		</div>
	)
}

export const getImageRatio = (width: number, image: CMSImage) => {
	return (width * image.height) / image.width
}

const switchProps = (image, as?: 'background' | 'landscape' | 'square') => {
	const focus = image.focus
		? `${image.focus?.x * 100}% ${image.focus?.y * 100}%`
		: '50% 50%'

	switch (as) {
		case 'background':
			return {
				layout: 'fill',
				objectFit: 'cover',
				objectPosition: focus
			}

		case 'landscape':
			return {
				layout: 'responsive',
				width: 640 * 2,
				height: getImageRatio(640, image) * 2
			}

		case 'square':
			return {
				layout: 'responsive',
				width: 600 * 2,
				height: 600 * 2,
				objectFit: 'cover',
				objectPosition: focus
			}

		default:
			return {...image}
	}
}
